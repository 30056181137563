import React from 'react'
import { auctionDisplayWithCode } from '../../utils/auction.util'
import { useStaticDataContext } from '../../context/StaticDataContext'
import { DisplayFlatTieredDescription } from './DisplayFlatTieredDescription'
import { DisplayFeeTierAmount } from './DisplayFeeTierAmount'
import { renderDateFromIsoString } from '../../utils/Formatters'
import { Popover } from '@interstate/components/Popover'

export function SearchResultsTableSimple({
    searchResultsTableData,
    feeSelectChange
}) {
    const staticData = useStaticDataContext()

    function handleCheckBoxChange(e, feeScheduleFlat) {
        feeSelectChange(feeScheduleFlat.id, e.target.checked)
    }
    function displayCreatedBy(feeScheduleFlat) {
        return (
            <Popover
                onOpen={function Ua() {}}
                popoverContent={renderDateFromIsoString(
                    feeScheduleFlat.createdTime,
                    'MM/DD/YYYY h:mm:ss a'
                )}
                trigger="hover">
                {feeScheduleFlat.createdBy}
            </Popover>
        )
    }
    function displayDescription(feeScheduleFlat) {
        if (feeScheduleFlat?.description?.length > 75) {
            return feeScheduleFlat.description.substring(0, 75) + '...'
        } else {
            return feeScheduleFlat.description
        }
    }
    return (
        <table className="patHtmlTable">
            <thead>
                <tr>
                    <th className="labelMute">Fee Schedule Type</th>
                    <th className="labelMute">Location</th>
                    <th className="labelMute">Sale type</th>
                    <th className="labelMute">Description</th>
                    <th className="labelMute">Customer</th>
                    <th className="labelMute">Fee type</th>
                    <th className="labelMute">Flat/Tiered</th>
                    <th className="labelMute">Amount/Tiers</th>
                    <th className="labelMute">Start date</th>
                    <th className="labelMute">End date</th>
                    <th className="labelMute">Created by</th>
                    <th className="labelMute"></th>
                </tr>
            </thead>
            <tbody>
                {searchResultsTableData &&
                    searchResultsTableData.map((feeScheduleFlat, index) => {
                        return (
                            <tr key={index}>
                                <td>{feeScheduleFlat.contractType}</td>
                                <td>
                                    {auctionDisplayWithCode(
                                        feeScheduleFlat.buCode,
                                        staticData.auctionInfoMap
                                    )}
                                </td>
                                <td>{feeScheduleFlat.saleType}</td>
                                <td>{displayDescription(feeScheduleFlat)}</td>
                                <td>{feeScheduleFlat.customer}</td>
                                <td>{feeScheduleFlat.feeType}</td>
                                <td>
                                    <div>
                                        <DisplayFlatTieredDescription
                                            feeScheduleFlat={
                                                feeScheduleFlat
                                            }></DisplayFlatTieredDescription>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <DisplayFeeTierAmount
                                            feeScheduleFlat={
                                                feeScheduleFlat
                                            }></DisplayFeeTierAmount>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {renderDateFromIsoString(
                                            feeScheduleFlat.startDate,
                                            'MM/DD/YYYY'
                                        )}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {renderDateFromIsoString(
                                            feeScheduleFlat.endDate,
                                            'MM/DD/YYYY'
                                        )}
                                    </div>
                                </td>
                                <td>{displayCreatedBy(feeScheduleFlat)}</td>
                                <td>
                                    {feeScheduleFlat.editableByUser && (
                                        <input
                                            className={'feeSelectCheckbox'}
                                            type="checkbox"
                                            name="feeSchedule"
                                            value={
                                                feeScheduleFlat.feeScheduleId
                                            }
                                            onChange={(e) =>
                                                handleCheckBoxChange(
                                                    e,
                                                    feeScheduleFlat
                                                )
                                            }
                                            checked={
                                                feeScheduleFlat.selectedFee
                                            }
                                        />
                                    )}
                                </td>
                            </tr>
                        )
                    })}
            </tbody>
        </table>
    )
}
